import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import Content from './Content';
import Header from './Header';
import Sidebar from './Sidebar';

const Layout = () => {
    const navigate = useNavigate();
    const cookies = new Cookies();

    const { student } = useSelector((store) => store.student);

    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const closeMobileMenu = () => setShowMobileMenu(false);
    const [open, setOpen] = useState(true);
    const token = cookies.get('accessToken');

    useEffect(() => {
        if (!student || student === 'undefined') {
            cookies.remove('accessToken');
            navigate('/login');
        }
        //eslint-disable-next-line
    }, []);

    if (student === undefined) return;

    return (
        <>
            {student.status !== 'Accepted' && student.isAcceptanceFee && <Navigate to="/acceptance" replace />}
            {token ? (
                <div className="">
                    <Sidebar open={open} />
                    <div className={`${open ? 'md:ml-[25rem]' : 'md:ml-[8.4rem]'} ml-[0] flex w-100% h-screen`}>
                        <div className="flex flex-col w-[100%]">
                            <Header
                                open={open}
                                setOpen={setOpen}
                                showMobileMenu={showMobileMenu}
                                setShowMobileMenu={setShowMobileMenu}
                                closeMobileMenu={closeMobileMenu}
                            />
                            <main
                                className={showMobileMenu ? 'hidden' : 'w-[100%] overflow-y-none mt-[2rem] px-[1.6rem]'}
                            >
                                <Content />
                            </main>
                        </div>
                    </div>
                </div>
            ) : (
                <Navigate to="/login" replace />
            )}
        </>
    );
};

export default Layout;
